.ant-popover-inner-content .explanation {
    padding: 10px;
    max-width: 500px;
}
.ant-popover-inner-content .add-options {
    padding: 10px;
    border-bottom: 1px solid #F3F1F1;
}

.link-icon .icon-info-circle-16 {
    margin-left: 5px;
    vertical-align: -0.1em;
    color: #514947;
    opacity: 0.7;
}

.link-icon {
    cursor: pointer;
    display: inline-block;
}

.explanation-title {
    font-family: 'Arial Rounded MT Bold Regular', sans-serif;
    color: #004B79;
    vertical-align: 0.1em;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}
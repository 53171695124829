.shadow-box {
    position: relative;
    box-shadow: 0 4px 16px 0 rgba(162, 160, 160, 0.24);
    background-color: #ffffff;
    padding: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 14px;
}

.shadow-box .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
}

.shadow-box .ant-collapse-header .acerta-icon {
    margin-top: -2px;
    vertical-align: -0.1em;
    font-size: 20px;
}

.shadow-box .ant-collapse-header h3 {
    padding-left: 30px;
}

.shadow-box .ant-collapse-header h3 {
    padding-left: 16px;
}

.shadow-box .ant-collapse-header h3.no-arrow {
    padding-left: 0;
}

.shadow-box .icon-chevron-down-16:before,
.shadow-box .icon-chevron-up-16:before {
    color: #326DAC;
}

.shadow-box .ant-collapse-content-box {
    padding-bottom: 0;
}

.shadow-box .subtext-and-icon {
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-style: italic;
    color: #a8a8a8;
    /*margin-left: 10px;*/
}
.shadow-box .subtext-and-icon .subtext {
    margin-left: 10px;
}

.voeg-toe-link {
    font-family: sans-serif;
    font-size: 12px;
    vertical-align: 0.7em;
    margin-right: 4px;
    text-decoration: underline;
}
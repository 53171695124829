@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 768px) {
    select,
    textarea,
    input {
        font-size: 16px !important;
    }
}

input.ant-input-number-input:disabled {
    -webkit-text-fill-color: #a8a8a8;
    opacity: 1; /* required on iOS */
}

.ant-layout-footer {
    background-color: #f9f9f9;
    padding: 0;
}

.ant-layout {
    background-color: #f9f9f9;
}

@media screen and (max-width: 768px) {
    input.ant-input-number-input:disabled {
        color: black !important;
    }
    .ant-layout, .ant-layout-footer {
        background-color: #F0F2F5 !important;
    }
    h1 {
        font-size: 30px;
    }

    h1.title {
        font-size: 20px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    h2.subtitle {
        font-size: 14px;
    }
}

/* kleiner dan of gelijk aan 767 */
@media screen and (max-width: 767px) {
    #landing-page-header {
        padding-top: 40px;
    }
    #landing-page-header h1 {
        font-size: 20px;
        margin-left: 0;
        padding-top: 80px;
    }
}

/* vanaf 768 */
@media screen and (min-width: 768px) {
    #landing-page-header {
        padding-top: 10px;
    }
    #landing-page-header h1 {
        font-size: 20px;
        margin-left: 0;
    }
}

/* tot 992 */
@media screen and (max-width: 991px) {

    #landing-page-header {
        /*padding-top: 10px;*/
        background-size: 1200px;
        background-position: right -50px top -20px;
        background-color: #eeeced;
        height: 100px;
    }

    #landing-page-header h1 {
        font-size: 24px;
        margin-left: 0;
    }
}

/* vanaf 992 */
@media screen and (min-width: 992px) {
    #landing-page-header {
        background-position: right -140px top -50px;
    }
    #landing-page-header h1 {
        font-size: 34px;
        margin-left: 0;
    }
}

/* vanaf 1200 */
@media screen and (min-width: 1200px) {
    #landing-page-header {
        background-position: right -100px top -50px;
    }
    #landing-page-header h1 {
        font-size: 36px;
    }
}

/* vanaf 1300 */
@media screen and (min-width: 1300px) {
    #landing-page-header {
        background-position: right top -50px;
    }
    #landing-page-header h1 {
        font-size: 38px;
    }
}

@media screen and (max-width: 400px) {
}
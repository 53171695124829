.acerta-yellow {
    color: #FFD500;
}

.acerta-orange {
    color: #EE7556;
}

.acerta-pink {
    color: #E8318A;
}

.acerta-light-blue-1 {
    color: #4CB4E7;
}

.acerta-light-blue-2 {
    color: #49A5DD;
}

.acerta-medium-blue-1 {
    color: #3F7CBF;
}

.acerta-medium-blue-2 {
    color: #326DAC;
}

.acerta-dark-blue {
    color: #004B79;
}

.acerta-grey-light {
    color: #F3F1F1;
}

.acerta-grey-medium {
    color: #D8D8D8;
}

.acerta-grey-less-dark {
    color: #a8a8a8;
}

.acerta-grey-dark {
    color: #514947;
}

.acerta-yellow-background {
    background-color: #FFD500;
}

.acerta-orange-background {
    background-color: #EE7556;
}

.acerta-pink-background {
    background-color: #E8318A;
}

.acerta-light-blue-1-background {
    background-color: #4CB4E7;
}

.acerta-light-blue-2-background {
    background-color: #49A5DD;
}

.acerta-medium-blue-1-background {
    background-color: #3F7CBF;
}

.acerta-medium-blue-2-background {
    background-color: #326DAC;
}

.acerta-dark-blue-background {
    background-color: #004B79;
}

.acerta-grey-light-background {
    background-color: #F3F1F1;
}

.acerta-grey-medium-background {
    background-color: #D8D8D8;
}

.acerta-grey-less-dark-background {
    background-color: #a8a8a8;
}

.acerta-grey-dark-background {
    background-color: #514947;
}

.negative-red {
    color: #ff4545;
}

.center {
    text-align: center;
}

.subtitle {
    font-family: 'Merriweather', serif;
    font-style: italic;
    color: #E8318A;
}

@font-face {
    font-family: 'Arial Rounded MT Bold Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Arial Rounded MT Bold Regular'), url('../fonts/ARLRDBD_0.woff') format('woff');
}

.rounded-font {
    font-family: 'Arial Rounded MT Bold Regular', sans-serif;
}

body {
    font-family: Arial, sans-serif;
    /*background-color: #f9f9f9;*/
}

h1, h2, h3, h4 {
    font-family: 'Arial Rounded MT Bold Regular', sans-serif;
}

h1 {
    font-size: 30px;
    color: #004B79;
}

h1.title {
    margin-top: -5px;
    margin-bottom: 0;
    font-size: 24px;
}

h2.subtitle {
    font-size: 18px;
}

h2 {
    font-size: 1.5em;
}

h2 {
    color: #4CB4E7;
}

h3 {
    margin-bottom: 20px;
    color: #004B79;
}

.ant-btn-link, .ant-btn-link:hover, .ant-btn-link:focus {
    color: #4CB4E7;
    padding: 0;
}

.ant-btn-primary {
    background-color: #E8318A;
    border-color: #E8318A;
    font-family: 'Arial Rounded MT Bold Regular', sans-serif;

}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background-color: #E8318A;
    border-color: #E8318A;
}

.ant-btn {
    border-radius: 0;
}

.ant-layout-header .language-select {
    font-family: 'Arial Rounded MT Bold Regular', sans-serif;
}

.ant-layout-header .language-select .ant-select-arrow {
    color: #4CB4E7;
}

.ant-layout-header .language-select .ant-select-selection {
    border: inherit;
    background-color: inherit;
    color: #004B79;
}

.ant-layout-header .language-select .ant-select-focused .ant-select-selection,
.ant-layout-header .language-select .ant-select-selection:focus,
.ant-layout-header .language-select .ant-select-selection:active {
    border-color: inherit;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.footer {
    /*position: fixed;*/
    left: 0;
    bottom: 0;
    width: 100%;
}

.ant-empty-normal {
    margin: 0;
}

.ant-form-explain {
    display: none;
}

.ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0;
}

.ant-collapse-item h3 {
    margin-bottom: 0;
}

.vorige-volgende {
    margin-top: 30px;
}

.light-link {
    font-family: 'Arial Rounded MT Bold Regular', sans-serif;
    font-size: 14px;
    color: #4cb4e7;
}

.ant-btn.light-link {
    padding: 0;
}

.ant-popover-inner {
    border-radius: 0;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    top: 1px;
    box-shadow: -4px -4px 5px rgba(0, 0, 0, 0.08);
}

.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
    left: 2px;
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    bottom: 1.2px;
}

.ant-popover-arrow {
    width: 17.485281px;
    height: 21.485281px;
    border-width: 9.242641px;
}

.ant-popover-inner-content {
    padding: 0;
}

#landing-page-header {
    background-size: 2000px;
    background-repeat: no-repeat;
    background-color: #eeeced;
    height: 220px;
}

.landing-page-header-nl {
    background-image: url('../images/header-zelfstandigen-nl.jpg');
}

.landing-page-header-fr {
    background-image: url('../images/header-zelfstandigen-fr.jpg');
}

.landing-page-header-en {
    background-image: url('../images/header-zelfstandigen-en.jpg');
}

#landing-page-header h1 {
    /*font-size: 34px;*/
}

#phone-background {
    background-image: url('../images/iphone8-plus.png');
    background-size: 100%;
    background-repeat: no-repeat;
    width: 300px;
    background-position-y: 2px;
}

#landing-page-header p {
    color: #004B79;
    font-size: 18px;
    margin-bottom: 0.5em;
}

#landing-page-visuals {
    background-size: 600px;
    height: 560px;
    background-position-x: 0px;
    background-position-y: 0px;
    background-repeat: no-repeat;
}

.landing-page-visuals-nl {
    background-image: url('../images/laptop-mobile-nl.jpg');
}

.landing-page-visuals-fr {
    background-image: url('../images/laptop-mobile-fr.jpg');
}

.landing-page-visuals-en {
    background-image: url('../images/laptop-mobile-en.jpg');
}

.collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

#profile-income div.ant-slider {
    padding-top: 0 !important;
}

#income-tooltopcontainer .ant-tooltip-content {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /*cursor: pointer;*/
}

#income-tooltopcontainer .ant-tooltip {
    z-index: 0 !important;
}

#income-tooltopcontainer .ant-tooltip-inner {
    background-color: #fff;
    border-radius: 0;
    padding-top: 3px;
    padding-bottom: 3px;
}

#income-tooltopcontainer .ant-tooltip-arrow::before,
#income-tooltopcontainer .ant-tooltip-arrow {
    width: 30px;
    height: 30px;
    background-color: #fff;
}

#income-tooltopcontainer .ant-tooltip-placement-top .ant-tooltip-arrow,
#income-tooltopcontainer .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
#income-tooltopcontainer .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    bottom: -1px;
    display: none;
}

.ant-checkbox-inner {
    border-color: #4CB4E7;
    border-radius: 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4CB4E7;
    border-color: #4CB4E7;
}

.ant-switch-checked {
    background-color: #4CB4E7;
}

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    cursor: default;
}

.ant-tooltip {
    z-index: 10000
}

#profile-income .ant-slider-handle {
    height: 24px;
    width: 24px;
    margin-top: -10px;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #4CB4E7;
    border-color: #4CB4E7;
}

.ant-radio-button-wrapper:hover {
    color: #004B79;
}

.ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper:last-child {
    border-radius: 0;
}

.striped {
    color: white;
    background: repeating-linear-gradient(
            45deg,
            #606dbc,
            #606dbc 10px,
            #465298 10px,
            #465298 20px
    );
}

.top-menu .ant-affix {
    z-index: 11 !important;
}

.acerta-icon {
    color: #4cb4e7;
}

.ant-select-dropdown {
    z-index: 9;
}

.ant-tooltip {
    /*z-index: 10000 !important;*/
}

.icon-expense-category-MATERIAAL:before {
    content: "\e924";
}
.icon-expense-category-OPSTART:before {
    content: "\e931";
}
.icon-expense-category-MACHINES_EN_GEREEDSCHAP:before {
    content: "\e929";
}
.icon-expense-category-VOERTUIGEN:before {
    content: "\e92b";
}
.icon-expense-category-HUISVESTING:before {
    content: "\e91d";
}
.icon-expense-category-MULTIMEDIA:before {
    content: "\e923";
}
.icon-expense-category-ADVIES_EN_INFORMATIE:before {
    content: "\e93c";
}
.icon-expense-category-OVERIGE:before {
    content: "\e933";
}
.icon-expense-category-PUBLICITEIT:before {
    content: "\e932";
}

.icon-expense-category-SOCIAL_SECURITY {
    background-image: url('../images/acerta-logo-a.png');
    background-repeat: no-repeat;
    background-position: 0px 2px;
    background-size: 18px;
    width: 21px;
    display: inline-block;
    height: 21px;
}

.item-excluded {
    color: #a8a8a8 !important;
}

.item-excluded, .item-excluded input, .item-excluded .ant-select-selection-selected-value {
    text-decoration: line-through;
}

.single-row-form-row {
    padding-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #eee;
}

.first .single-row-form-row {
    border-top: 1px solid #D8D8D8
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 26px;
}

.bold {
    font-weight: bold;
}

.ant-input-number-handler-wrap {
    display: none !important;
}

.input-currency, .input-amount, .input-currency-disabled, .input-amount-disabled {
    background-size: 14px 14px;
    padding-left: 8px;
    background-repeat: no-repeat;
    background-position: 2px 8px;
}

.input-currency {
    background-image: url('../images/euro_49A5DD.png');
}

.input-amount {
    background-image: url('../images/hash_49A5DD.png');
}

.input-currency-disabled {
    background-image: url('../images/euro_999.png');
}

.input-amount-disabled {
    background-image: url('../images/hash_999.png');
}

.mail-capture-form .ant-form-item {
    margin-bottom: 10px;
}

.enter-code-form .ant-form-item {
    margin-bottom: 20px;
}

.calculator-button, .calculator-button-excluded {
    margin-right: 10px;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
}

.calculator-button, .calculator-button-excluded {
    background-color: #4CB4E7;
    border-color: #4CB4E7;
}

.ant-btn.calculator-button:hover,
.ant-btn.calculator-button:focus,
.ant-btn.calculator-button:active,
.ant-btn.calculator-button.active {
    text-decoration: none;
    background-color: #4CB4E7;
    border-color: #4CB4E7;
}

.ant-collapse-arrow {
    margin-top: -6px;
    transform: rotate(270deg) !important;
}

.ant-collapse-item-active .ant-collapse-arrow {
    transform: rotate(0deg) !important;
}

.switch-background {
    background-image: url('../images/switch.png');
    background-repeat: no-repeat;
    background-position: 3px 0px;
    background-size: 24px;
    vertical-align: -0.5em;
    width: 27px;
    height: 19px;
    display: inline-block;
}

.light-shadow {
    -webkit-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.11);
    -moz-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.11);
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.11);
}

#ProfileView_sectors .ant-select-selection.ant-select-selection--multiple {
    cursor: pointer;
}

#ProfileView_sectors .ant-select-search.ant-select-search--inline input {
    color: #fff;
}

#ProfileView_sectors .ant-select-search__field,
#ProfileView_sectors .ant-select-search__field__mirror {
    color: #fff;
    width: 0px;
}

#cookie-policy {
    -webkit-box-shadow: 0px -4px 15px -2px rgba(0,0,0,0.23);
    -moz-box-shadow: 0px -4px 15px -2px rgba(0,0,0,0.23);
    box-shadow: 0px -4px 15px -2px rgba(0,0,0,0.23);
}

.sofie-avatar-small {
    background-image: url('../../assets/images/sofie_avatar.png');
    background-size: 24px;
    background-repeat: no-repeat;
    display: inline-block;
    height: 24px;
    width: 24px;
    vertical-align: -0.4em;
    margin-left: 5px;
}

.underline > span {
    text-decoration: underline;
}

.sofie-help-text h3 {
    margin-bottom: 16px;
}
.sofie-help-text p {
    margin-bottom: 0.6em;
}

.acerta-icon-sofie {
    color: #4cb4e7;
    font-size: 16px;
    margin-top: 0px;
    margin-right: 10px;
    vertical-align: -0.1em;
    margin-left: -26px;
}

.with-arrow {
    padding-left: 26px;
}

.ant-select-dropdown-menu-item.vergunning-dropdown-item {
    overflow: auto;
    line-height: 22px;
    white-space: normal;
    text-overflow: ellipsis;
}
.simulation-steps-row {
    -webkit-box-shadow: 0px 10px 6px -2px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 10px 6px -2px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 10px 6px -2px rgba(0, 0, 0, 0.04);
}

.simulation-steps .ant-steps-item-content {
    font-family: 'Arial Rounded MT Bold Regular', sans-serif;
    margin-top: -50px;
    /*margin-bottom: 50px;*/
}

.ant-steps.ant-steps-horizontal.simulation-steps.ant-steps-label-vertical.ant-steps-dot.mobile {
    margin-left: -64px;
}

.ant-steps.ant-steps-horizontal.simulation-steps.ant-steps-label-vertical.ant-steps-dot {
    margin-left: -42px;
}

div.ant-steps-item.ant-steps-item-process.ant-steps-item-active > div > div.ant-steps-item-icon > span > span > span.ant-steps-icon-dot {
    background-color: #E8318A;
    width: 16px;
    height: 16px;
    top: -4px;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #E8318A;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #E8318A !important;
}

div.ant-steps-item-title {
    padding-left: 10px !important;
}

div.ant-steps-item-tail {
    margin-left: 81px !important;
    padding-right: 9px !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    height: 16px;
    width: 16px;
    top: -4px;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: transparent;
    border: 2px solid #a8a8a8;

}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #a8a8a8;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
div.ant-steps-item-icon > span > span.ant-steps-icon-dot,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: #49A5DD;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title {
    color: #49A5DD;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .step-number {
    border-color: #49A5DD !important;
}

.ant-steps-item-icon > .ant-steps-icon {
    top: -8px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #004B79;
}

.ant-steps-dot .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
    height: 2px;
}

@media screen and (max-width: 767px) {
    .ant-steps-item-container {
        width: 30px;
    }
    .ant-steps-item-title {
        font-size: 12px !important;
    }
    .simulation-steps .ant-steps-item-content {
        margin-top: -40px;
    }
}

@media screen and (max-width: 1100px) and (min-width: 768px) {
    .ant-steps-item {
        /*width: 100px !important;*/
    }
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    height: 8px !important;
}
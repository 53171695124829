#header {
    padding-top: 5px;
}

.ant-layout-header {
    padding: 0;
    height: 80px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #f3f1f1;
}

.header-logo {
    height: 32px;
    margin-top: 24px;
    margin-bottom: 24px;
}

h1#header-title {
    display: inline-block;
    padding-left: 20px;
    font-size: 22px;
    color: #4CB4E7;
}

@media screen and (max-width: 820px) {
    h1#header-title {
        font-size: 22px;
    }
}

@media screen and (max-width: 767px) {
    #header {
        padding-top: 0;
    }

    .ant-layout-header {
        height: 60px;
    }
    .header-logo {
        height: 26px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    h1#header-title {
        padding-left: 10px;
        font-size: 16px;
        color: #4CB4E7;
    }
}

@media screen and (max-width: 370px) {
    h1#header-title {
        font-size: 12px;
    }
}
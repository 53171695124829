.tab {
    cursor: pointer;
    background-image: url('../../../../assets/images/drop-shadow.png');
    background-repeat: repeat-x;
    background-color: #fff;
    background-size: 160px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #efefef;
    border-top: none;
    align-items: center;
    padding: 8px;
    padding-top: 10px;
    padding-bottom: 5px;
    -webkit-box-shadow: 0px 4px 7px 1px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 4px 7px 1px rgba(0,0,0,0.05);
    box-shadow: 0px 4px 7px 1px rgba(0,0,0,0.05);
}

.tab-mobile {
    cursor: pointer;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid #efefef;
    border-right: none;
    padding: 8px;
    padding-right: 20px;
    width: 60px;
    z-index: 11;
    -webkit-box-shadow: -4px 0px 7px -1px rgba(0,0,0,0.05);
    -moz-box-shadow: -4px 0px 7px -1px rgba(0,0,0,0.05);
    box-shadow: -4px 0px 7px -1px rgba(0,0,0,0.05);
}

.tab-no-image {
    background-image: none;
}

.tab-drawer {
    background-image: url('../../../../assets/images/drop-shadow.png');
    background-repeat: repeat-x;
    background-color: #fff;
    background-size: 160px;
    -webkit-box-shadow: 0px 4px 7px 1px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 4px 7px 1px rgba(0,0,0,0.05);
    box-shadow: 0px 4px 7px 1px rgba(0,0,0,0.05);
    transition: 0.3s
}
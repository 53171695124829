.bar {
    transition: 0.3s
}

.bar:hover, .bar.hover {
    opacity: 0.5;
    transition: 0.3s
}

.bar-inkomsten {
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-align: center;
    min-height: 52px;
    /*background-color: #EE7556;*/
    background-color: #4CB4E7;
}

.bar-inkomsten.less {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.bar-inkomsten-netto, .bar-inkomsten-netto-accolade {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: center;
    color: #fff;
    /*border-top: 1px dashed #fff;*/
    /*background-image: linear-gradient(135deg, #4CB4E7 36.36%, #3F7CBF 36.36%, #3F7CBF 50%, #4CB4E7 50%, #4CB4E7 86.36%, #3F7CBF 86.36%, #3F7CBF 100%);*/
    /*border-top: 1px solid #fff;*/
    /*background-image: linear-gradient(135deg, #3F7CBF 36.36%, #4CB4E7 36.36%, #4CB4E7 50%, #3F7CBF 50%, #3F7CBF 86.36%, #4CB4E7 86.36%, #4CB4E7 100%);*/
    background-size: 15.56px 15.56px;
}

.bar-inkomsten-netto {
    background-image: linear-gradient(135deg, #4CB4E7 36.36%, #3F7CBF 36.36%, #3F7CBF 50%, #4CB4E7 50%, #4CB4E7 86.36%, #3F7CBF 86.36%, #3F7CBF 100%);
}

.bar-inkomsten-netto-accolade {
    border-top: 1px dashed #fff;
    background-color: #4CB4E7;
}

.legend {
    padding-left: 5px;
    border-radius: 4px;
    font-size: 12px;
    padding-right: 5px;
}

.legend-hover {
    background-color: #eee;
    transition: 0.3s
}

.net {
    font-family: 'Arial Rounded MT Bold Regular', sans-serif;
}

.calculated .net {
    font-size: 24px;
    color: #49A5DD;
    margin-bottom: -2px;

}

.desired .net {
    font-size: 18px;
    color: #a8a8a8;
    margin-right: 40px;
}

.comparison .calculated, .comparison .desired {
    display: inline-block;
}

.comparison .calculated {
    margin-right: 0;
    margin-left: 30px;
}

.chart-color-shortage, .striped-background {
    /*background-image: linear-gradient(45deg, #FFD0D0 36.36%, #FFA2A2 36.36%, #FFA2A2 50%, #FFD0D0 50%, #FFD0D0 86.36%, #FFA2A2 86.36%, #FFA2A2 100%);*/
    /*background-size: 15.56px 15.56px;*/
    background-image: linear-gradient(135deg, #ffa2a2 25%, #ff4545 25%, #ff4545 50%, #ffa2a2 50%, #ffa2a2 75%, #ff4545 75%, #ff4545 100%);
    background-size: 14.14px 14.14px;
}

.chart-color-surplus {
    /*background-image: linear-gradient(135deg, #f9f9f9 36.36%, #dddddd 36.36%, #dddddd 50%, #f9f9f9 50%, #f9f9f9 86.36%, #dddddd 86.36%, #dddddd 100%);*/
    /*background-size: 15.56px 15.56px;*/
    background-image: linear-gradient(135deg, #7DEB9C 25%, #C3FFC3 25%, #C3FFC3 50%, #7DEB9C 50%, #7DEB9C 75%, #C3FFC3 75%, #C3FFC3 100%);
    background-size: 14.14px 14.14px;
}

.legend-shortage, .legend-surplus  {
    border: 1px solid #ddd;
    height: 12px;
    width: 12px;
}

.chart-color-yearlyCost {
    background-color: #E8318A;
    /*background-color: #004B79;*/
}

.chart-color-socialContributions {
    background-color: #EE7556;
    /*background-color: #3F7CBF;*/
}

.chart-color-personalIncomeTax {
    background-color: #FFD500;
    /*background-color: #4CB4E7;*/
}

.chart-color-calculatedYearlyNetIncome {
    background-color: #3F7CBF;
    /*background-color: #004B79;*/
    /*background-color: #E8318A;*/
}

.chart-color-yearlyExpense {
    background-color: #E8318A;
}

.chart-color-contributionResources {
    background-color: #004B79;
}

.chart-color-yearlyRevenue{
    background-color: #4CB4E7;
}

#accolade div {
    background-color: #3F7CBF;
}
@font-face {
  font-family: 'acertafintool';
  src:  url('../fonts/acertafintool.eot?mtpwxl');
  src:  url('../fonts/acertafintool.eot?mtpwxl#iefix') format('embedded-opentype'),
    url('../fonts/acertafintool.ttf?mtpwxl') format('truetype'),
    url('../fonts/acertafintool.woff?mtpwxl') format('woff'),
    url('../fonts/acertafintool.svg?mtpwxl#acertafintool') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'acertafintool' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert-triangle-16:before {
    content: "\e900";
}
.icon-arrow-down-16:before {
    content: "\e901";
}
.icon-arrow-up-16:before {
    content: "\e902";
}
.icon-check-16:before {
    content: "\e903";
}
.icon-check-circle-fill-16:before {
    content: "\e904";
}
.icon-chevron-down-16:before {
    content: "\e905";
}
.icon-chevron-left-16:before {
    content: "\e906";
}
.icon-chevron-right-16:before {
    content: "\e907";
}
.icon-chevron-up-16:before {
    content: "\e908";
}
.icon-drag-16:before {
    content: "\e909";
}
.icon-external-link-16:before {
    content: "\e90a";
}
.icon-help-circle-16:before {
    content: "\e90b";
}
.icon-help-circle-fill-16:before {
    content: "\e90c";
}
.icon-info-circle-16:before {
    content: "\e90d";
}
.icon-loading-16:before {
    content: "\e90e";
}
.icon-minus-16:before {
    content: "\e90f";
}
.icon-minus-circle-16:before {
    content: "\e910";
}
.icon-plus-16:before {
    content: "\e911";
}
.icon-plus-circle-16:before {
    content: "\e912";
}
.icon-x-16:before {
    content: "\e913";
}
.icon-zap-16:before {
    content: "\e914";
}
.icon-arrow-right-24:before {
    content: "\e92e";
}
.icon-bank-24:before {
    content: "\e92f";
}
.icon-calculator-24:before {
    content: "\e930";
}
.icon-checklist-24:before {
    content: "\e931";
}
.icon-coaching-24:before {
    content: "\e932";
}
.icon-database-24:before {
    content: "\e933";
}
.icon-download-24:before {
    content: "\e934";
}
.icon-employer-24:before {
    content: "\e935";
}
.icon-minus-circle-24:before {
    content: "\e936";
}
.icon-partner-24:before {
    content: "\e937";
}
.icon-plus-24:before {
    content: "\e938";
}
.icon-plus-circle-24:before {
    content: "\e939";
}
.icon-search-24:before {
    content: "\e93a";
}
.icon-sliders-24:before {
    content: "\e93b";
}
.icon-training-24:before {
    content: "\e93c";
}
.icon-arrow-left-24:before {
    content: "\e915";
}
.icon-briefcase-24:before {
    content: "\e916";
}
.icon-close-24:before {
    content: "\e917";
}
.icon-download-cloud-24:before {
    content: "\e918";
}
.icon-edit-3-24:before {
    content: "\e919";
}
.icon-edit-24:before {
    content: "\e91a";
}
.icon-eye-24:before {
    content: "\e91b";
}
.icon-eye-off-24:before {
    content: "\e91c";
}
.icon-home-24:before {
    content: "\e91d";
}
.icon-info-circle-24:before {
    content: "\e91e";
}
.icon-info-circle-fill-24:before {
    content: "\e91f";
}
.icon-lock-24:before {
    content: "\e920";
}
.icon-mail-24:before {
    content: "\e921";
}
.icon-message-circle-24:before {
    content: "\e922";
}
.icon-monitor-24:before {
    content: "\e923";
}
.icon-package-24:before {
    content: "\e924";
}
.icon-pie-chart-24:before {
    content: "\e925";
}
.icon-printer-24:before {
    content: "\e926";
}
.icon-save-24:before {
    content: "\e927";
}
.icon-settings-24:before {
    content: "\e928";
}
.icon-tool-24:before {
    content: "\e929";
}
.icon-trash-24:before {
    content: "\e92a";
}
.icon-truck-24:before {
    content: "\e92b";
}
.icon-upload-cloud-24:before {
    content: "\e92c";
}
.icon-users-24:before {
    content: "\e92d";
}